/* @flow */

import type { ConnectedPartner } from "shop-state/types";

import React from "react";
import Wrapper from "components/Wrapper";
import Carousel from "components/Carousel";
import companyData from "../CompanyList/companyData.json";

import styles from "./styles.scss";

type Props = {
  partners: [ConnectedPartner],
};

const HomeHero = ({ partners }: Props) => {
  const mapPartnerImages = () => {
    return partners.map((partner, i) => {
      const company = companyData.sgds.find(item => {
        return item.name.toLowerCase() === partner.name.toLowerCase();
      });

      return partner.connected && company && company.hero !== undefined ? (
        <div key={i} className={styles.contentHero}>
          <img
            className={styles.itemImage}
            alt={partner.name}
            src={company.hero}
          />
        </div>
      ) : null;
    });
  };

  const items = mapPartnerImages();
  const filteredItems = items.filter(el => el != null);
  const adaptiveHeight = true;

  return (
    <Wrapper className={styles.wrapper}>
      {filteredItems.length > 0 ? (
        <Carousel
          autoplay={false}
          className={styles.carousel}
          items={filteredItems}
          adaptiveHeight={adaptiveHeight}
          slidesToScroll={1}
          slidesToShow={1}
        />
      ) : <DummyHero />
      }
    </Wrapper>
  );
};

const DummyHero = () => {
  return (
    <div className={styles.dummyHero}>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default HomeHero;
