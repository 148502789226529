/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

const About = () => {
  const storeInfo = useContext(StoreInfoContext);
  const { configuration: { helpdeskLink } } = storeInfo;
  return (
    <Wrapper>
      <Helmet
        title="Välkommen till Proffsklubben​"
      />
      <h1 className={styles.title}>Välkommen till Proffsklubben​</h1>
      <div className={styles.wrapper}>
        <div className={styles.intro}>
          <img src="/assets/images/intro.png" />
          <div className={styles.introText}>
            <p>
              Proffsklubben är klubben för dig som är kund hos
              något av våra bolag inom Saint-Gobain Distribution Sweden.
              Dessa är Bevego, Dahl, Optimera, Kakelspecialisten och Konradssons Kakel.
              Som medlem i Proffsklubben kan du tjäna poäng när du handlar hos något
              av våra bolag och få poängen insatta på ett och samma ställe, nämligen Proffsklubben!
            </p>
            <p>
              Inte nog med det, genom utvalda samarbetspartners som
              CDON, Avis, Guldfynd, Däckonline, Media Markt och många
              fler kan du samla extra poäng. Poängen kan du sedan använda
              i vår exklusiva premieshop med tusentals prylar.
            </p>
            <p>Dessutom får du tillgång till massor av förmåner på smarta
              saker som förenklar din vardag. Här finns allt från rabatt på
              bilar och drivmedel till försäkring och telefoni – inte illa eller hur?
            </p>
          </div>
        </div>
        <div className={styles.benefits}>
          <div>
            <h2>Medlemsförmåner</h2>
            <b>
              Som medlem i Proffsklubben får du bland annat:
            </b>
            <ul className={styles.benefitsList}>
              <li>
                Förmånliga erbjudanden på nödvändig utrustning, exempelvis bilar och drivmedel
              </li>
              <li>
                Utvalda proffskampanjer från våra bolag där du handlar
              </li>
              <li>
                Samla poäng som kan användas i en exklusiv premieshop
              </li>
              <li>
                Samla poäng genom att handla via klubbsajten hos cirka 300 partnerföretag
              </li>
              <li>
                Kombinera poäng med pengar om poäng saknas för önskad vara i premieshopen
              </li>
              <li>
                Använda dina poäng till kreditfaktura i det bolag du tjänat in poängen
              </li>
              <li>
                Inbjudningar till event, utbildningar och tävlingar
              </li>
            </ul>
          </div>
          <div>
            <img src="/assets/images/benefits.png" />
          </div>
        </div>
        <div id="faq" className={styles.faq}>
          <h2>Vanliga frågor och svar om Proffsklubben​</h2>
          <p>
            Välkommen till Proffsklubben. Här har vi listat de
            vanligaste frågorna och svaren om kundklubben.
          </p>
          <h3>Är det samma poängintjäning i alla bolag?</h3>
          <p>
            Intjänade poäng per inköpskrona kan variera mellan våra bolag.
            Kundklubben är ett av alla mervärden vi erbjuder dig som kund.<br /> För
            mer information om vad som gäller för dig, fråga din säljare där du handlar.
          </p>
          <h3>Får jag som medlem automatiskt konto i de andra bolagen
            som tillhör Saint-Gobain Distrubition Sweden?
          </h3>
          <p>Nej, men du kan enkelt ansöka <a href="/companies">här.</a></p>
          <h3>Jag vill bli medlem i fler bolag – hur gör jag då?</h3>
          <p>Kontakta en säljare på det bolag där du önskar få medlemskap.
            De hjälper dig med din ansökan om ditt företag uppfyller kriterierna.
          </p>

          <h3>Vad finns det för fördelar med att vara med i kundklubben?</h3>
          <p>
            Ja förutom att tjäna poäng när du handlar så har vi rabatter via våra partners
            och man kan också tjäna extra poäng genom att handla hos våra poängpartners.
            Med poängen kan du handla i vår premieshop med tusentals produkter som uppdateras
            löpande. Via klubben kommer du också att kunna delta i tävlingar och event.
          </p>
          <h3>Jag hittar inte den produkten jag söker i premieshopen. Vad gör jag då?</h3>
          <p>Du kan enkelt skicka in ditt önskemål till vår
            kundtjänst via kontaktformuläret som du hittar <a href={helpdeskLink}>här.</a>
          </p>
          <h3>Var kan jag hitta medlemsvillkor och kriterier för bolagen?</h3>
          <p>Du hittar de allmänna villkoren för Proffsklubben <a href="/terms-conditions">här.</a> Du kan också läsa bolagens egna kriterier <a href="/companies">här.</a></p>
          <h3>Jag har frågor om min beställning, leverans eller annat som berör premieshopen
            – vart vänder jag mig?
          </h3>
          <p>
            Du når vår kundtjänst via kontaktformuläret <a href={helpdeskLink}>här.</a> Du
            kan också ringa oss vardagar mellan kl. 9-12 och 14-16 på 08-588 90 702.
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default About;
