/* @flow */

import type { CurrentInfoListItem } from "shop-state/types";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "components/CurrentInfo/CurrentInfoListView/Card/styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext } from "@crossroads/analytics";
import cn from "classnames";

type Props = {
  path: string,
  block: CurrentInfoListItem,
};

const Card = ({ path, block }: Props) => {
  const gaContext = useContext(AnalyticsContext);
  const t = useTranslate();
  const gaRegister = () => {
    gaContext.registerContentClick(`CurrentInfo: ${block.title ?? ""}`, block.id.toString());
  };

  return (
    <div className={styles.card}>
      <div className={styles.shadow} />
      <div className={styles.content}>
        {block.image && (
          <div className={styles.image}>
            <img src={block.image} alt="" />
          </div>
        )}
        <div className={styles.contentText}>
          {block.title && (
            <h2 className={styles.title}>{block.title}</h2>
          )}
          {/* eslint-disable react/no-danger */}
          {block.description && (
            <div
              dangerouslySetInnerHTML={{ __html: block.description }}
              className={styles.description}
            />
          )}
          {/* eslint-enable react/no-danger */}

        </div>
        <div className={styles.meta}>
          <Link
            className={styles.link}
            to={{
              pathname: `${path}/${block.id}`,
              state: { hint: { title: block.title } },
            }}
            onClick={gaRegister}
          >
            <span className={cn(styles.readMore, "link")}>{t("COMMON.READ_MORE")}</span>
          </Link>
          {block.tags && block.tags.length > 0 &&
          <div className={styles.tags}>
            {block.tags.map((tag, i) => (
              <span key={i} className={styles.tag}>{tag}</span>
            ))}
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export const DummyCard = () => {
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <div className={styles.title} />
        <div className={styles.image} />
        <div className={styles.description} />
      </div>
    </div>
  );
};

export default Card;
