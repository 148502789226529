/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import { StoreInfoContext } from "entrypoint/shared";

import companyData from "./companyData.json";

import styles from "./styles.scss";

type CompanyProps = {
  name: string,
  image: string,
  email?: string,
  phone?: string,
  address?: string,
  website?: string,
  websiteLink?: string,
  description?: string,
};

const Company = ({
  name, image, email, phone, address, website, websiteLink, description,
}: CompanyProps) => (
  <div className={styles.item}>
    <div className={styles.imageWrapper}>
      <img
        className={styles.image}
        alt={name}
        src={image}
      />
    </div>
    <div>
      {name &&
        <p className={styles.name}>
          {name}
        </p>
      }
      {email &&
        <p className={styles.email}>
          {email}
        </p>
      }
      {phone &&
        <p className={styles.phone}>
          {phone}
        </p>
      }
      {address &&
        <p className={styles.address}>
          {address}
        </p>
      }
      {/* eslint-disable react/no-danger */}
      {description && (
        <div dangerouslySetInnerHTML={{ __html: description }} className={styles.text} />
      )}
      {/* eslint-enable react/no-danger */}
      {website && websiteLink &&
      <a
        className={styles.link} href={websiteLink} rel="noopener noreferrer"
        target="_blank"
      >{website}
      </a>}
    </div>
  </div>
);

const CompanyContact = () => {
  const storeInfo = useContext(StoreInfoContext);
  const { configuration: { helpdeskLink } } = storeInfo;
  return (
    <Wrapper className={styles.mainWrapper}>
      <Helmet
        title="Våra bolag - Kontakt"
      />
      <h1 className={styles.title}>Frågor som rör Proffsklubben</h1>
      <div className={styles.mainContact}>
        {helpdeskLink &&
        <Company
          name="Kontakt med Proffsklubben" image="/assets/images/proffsklubben-color.png" description={`<p>Har du frågor gällande inloggning, tjäna poäng via våra poängpartners, produkter, beställningar och leveranser från premieshopen?
          Kontakta oss gärna.</br></br>
          <b>Telefon:</b> <a rel='noopener noreferrer'
          target='_blank' href="callto:0858890702">08-588 90 702</a></br><b>Öppet:</b> vardagar mellan 9-12 och 14-16.</br></br>
          <b>Kontaktformulär kundtjänst:</b></br>
          <a rel='noopener noreferrer'
          target='_blank' href='${helpdeskLink}'>${helpdeskLink}</a> </br>Vi svarar så snart vi kan, senast inom 48 timmar.</p>`} />
        }
      </div>
      <h2 className={styles.title}>Bolagsspecifika frågor</h2>
      <div className={styles.contact}>
        {companyData.sgds.map((item, index) => (
          <Company key={item.name + index} {...item} />
        ))}
      </div>
    </Wrapper>
  );
};

export default CompanyContact;
