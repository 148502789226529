/* @flow */

import React from "react";
import cn from "classnames";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  icon?: React$Node,
  acceptanceText: string,
  accepted: boolean,
  callback: (value: boolean) => void,
};

const Notice = ({
  className,
  icon,
  acceptanceText,
  callback,
  accepted,
}: Props) => {
  return (
    <div
      className={cn(
        styles.block,
        { [styles.visible]: accepted },
        className
      )}
    >
      {!accepted ? (
        <aside className={cn(styles.notice, className)}>
          <div className={styles.content}>
            <div className={styles.icon}>{icon}</div>
            {/* eslint-disable react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: acceptanceText }} className={styles.text} />
            {/* eslint-enable react/no-danger */}
            <div className={styles.close} onClick={() => callback(true)}><CloseIcon /></div>
          </div>
        </aside>
      ) : null}
    </div>
  );
};

export default Notice;
