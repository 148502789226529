/* @flow */

import React, { useRef, useEffect, useLayoutEffect, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import useSearchSuggestions from "helpers/use-search-suggestions";
import SearchIcon from "icons/search.svg";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";

type Props = {
  className?: string,
  autoFocus?: boolean,
};

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

const SearchMenu = ({ className = "", autoFocus = false }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const t = useTranslate();
  const inputRef = useRef(null);
  const [errorText, setErrorText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const searchSuggestions = useSearchSuggestions(searchQuery);
  const gaContext = useContext(AnalyticsContext);

  // Close on page switch
  useEffect(() => {
    setSearchQuery("");
  }, [location]);

  useIsomorphicLayoutEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  });

  const onSubmit = (e: Event) => {
    e.preventDefault();

    if (inputRef.current !== null) {
      const { value } = inputRef.current;

      if (!value || value.length <= 1) {
        setErrorText(t("SEARCH.ERROR_MINIMUM_CHARACTERS", { characters: 2 }));
        return;
      }

      history.push(`/search/${value}`);
      gaContext.searchTerm(value);
    }
  };

  return (
    <div className={cn(className, styles.block)}>
      <form onSubmit={onSubmit}>
        <div className={styles.container}>
          <button type="submit" className={styles.submit}>
            <SearchIcon className={styles.icon} />
          </button>

          <input
            ref={inputRef}
            value={searchQuery}
            className={styles.input}
            type="text"
            placeholder={t("SEARCH.PLACEHOLDER")}
            onChange={e => setSearchQuery(e.target.value)}
          />

          {errorText &&
            <span
              className={styles.error}
            >{errorText}
            </span>
          }
        </div>

        {(searchQuery.length > 1 && searchSuggestions.length > 1) &&
          <section className={styles.suggestions}>
            <Link
              className={styles.suggestion}
              style={{ zIndex: 101 }}
              to={`/search/${searchQuery}`}
            >
              <div className={styles.suggestionBorder} />

              <div className={styles.suggestionWrapper}>
                <p className={styles.suggestionName}>
                  {`"${searchQuery}"`}
                </p>
              </div>
            </Link>
            {searchSuggestions.map((x, i) => (
              <Link
                key={x.name}
                className={styles.suggestion}
                style={{ zIndex: 100 - i }}
                to={x.urlKey}
              >
                <div className={styles.suggestionBorder} />

                <div className={styles.suggestionWrapper}>
                  <p className={styles.suggestionName}>
                    {x.name}
                  </p>
                  {x.type !== "product" &&
                    <p className={styles.suggestionType}>
                      {t(`SEARCH.TYPE.${x.type.toUpperCase()}`)}
                    </p>
                  }
                </div>
              </Link>
            ))}
          </section>
        }
      </form>
    </div>
  );
};

export default SearchMenu;
